import React, { useState, useEffect } from 'react';
import { Code2, Layout} from 'lucide-react';

const Experience = () => {
  const [activeCard, setActiveCard] = useState(null);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const skillCategories = [
    {
      title: "Frontend Development",
      icon: Layout,
      color: "from-[#5BC0BE] to-[#6FFFE9]",
      skills: [
        { name: "HTML", level: 95, desc: "Semantic markup, accessibility, SEO" },
        { name: "CSS", level: 90, desc: "Responsive design, animations, Tailwind" },
        { name: "JavaScript", level: 85, desc: "ES6+, DOM manipulation, async" },
        { name: "TypeScript", level: 80, desc: "ES6+, DOM manipulation, async" },
        { name: "React", level: 75, desc: "Hooks, state management, components" },
      ]
    },
    {
      title: "Software Development",
      icon: Code2,
      color: "from-[#6FFFE9] to-[#5BC0BE]",
      skills: [
        { name: "C#", level: 95, desc: ".NET Framework, LINQ, async/await" },
        { name: "Python", level: 90, desc: "Django, automation, data processing" },
        { name: "Unity", level: 85, desc: "Game development, 3D graphics, physics" },
        { name: "Node.js", level: 80, desc: "Express, APIs, server-side dev" },
        { name: "Selenium", level: 80, desc: "Test automation, web scraping" },
      ]
    }
  ];

  // We'll use windowWidth directly in our component instead of these variables
  
  // Get appropriate icon size based on screen width
  const getIconSize = () => {
    if (windowWidth < 375) return "w-4 h-4"; // Extra small screens
    if (windowWidth < 640) return "w-5 h-5"; // Small screens
    return "w-6 h-6"; // Default
  };

  const SkillCard = ({ skill, isActive, onClick }) => (
    <div
      className={`relative group cursor-pointer transition-all duration-300 ${
        isActive ? 'scale-105' : 'hover:scale-102'
      }`}
      onClick={onClick}
    >
      <div className="bg-[#0B132B]/50 backdrop-blur-sm p-3 sm:p-4 rounded-lg sm:rounded-xl border border-gray-800/50 hover:border-[#6FFFE9]/50 transition-all duration-300">
        <div className="flex items-center justify-between mb-2 sm:mb-3">
          <h4 className="text-base sm:text-lg font-medium text-gray-200">{skill.name}</h4>
          <span className="text-xs sm:text-sm text-[#6FFFE9]">{skill.level}%</span>
        </div>

        <div className="relative h-1.5 sm:h-2 bg-[#1C2541] rounded-full overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] rounded-full transition-all duration-500"
            style={{ width: `${skill.level}%` }}
          />
        </div>

        <div className={`mt-2 sm:mt-3 text-xs sm:text-sm text-gray-400 transition-all duration-300 ${
          isActive ? 'opacity-100 max-h-20' : 'opacity-0 max-h-0'
        } overflow-hidden`}>
          {skill.desc}
        </div>
      </div>
    </div>
  );

  const CategoryCard = ({ category, index }) => (
    <div className="relative group">
      {/* Background glow - reduced on small screens */}
      <div className={`absolute inset-0 bg-gradient-to-r ${category.color} rounded-xl sm:rounded-2xl blur-lg sm:blur-xl opacity-10 sm:opacity-20 group-hover:opacity-30 transition-all duration-500`} />
      
      {/* Content container - adjusted padding for small screens */}
      <div className="relative bg-[#0B132B]/50 backdrop-blur-lg p-4 sm:p-6 md:p-8 rounded-xl sm:rounded-2xl border border-gray-800/50">
        {/* Header - smaller on mobile */}
        <div className="flex items-center gap-2 sm:gap-3 mb-4 sm:mb-6 md:mb-8">
          <div className={`p-1.5 sm:p-2 rounded-md sm:rounded-lg bg-gradient-to-r ${category.color}`}>
            <category.icon className={getIconSize() + " text-[#0B132B]"} />
          </div>
          <h3 className="text-lg sm:text-xl font-bold text-gray-200">{category.title}</h3>
        </div>
        
        {/* Skills grid - smaller gap on mobile */}
        <div className="grid gap-3 sm:gap-4">
          {category.skills.map((skill, skillIndex) => (
            <SkillCard
              key={skillIndex}
              skill={skill}
              isActive={activeCard === `${index}-${skillIndex}`}
              onClick={() => setActiveCard(activeCard === `${index}-${skillIndex}` ? null : `${index}-${skillIndex}`)}
            />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <section id="experience" className="py-16 sm:py-20 md:py-24 px-4 bg-gradient-to-b from-[#0B132B] to-[#1C2541]">
      <div className="text-center mb-8 sm:mb-12 md:mb-16">
        <h5 className="text-gray-400 text-base sm:text-lg mb-1 sm:mb-2">The Skills I Have</h5>
        <h2 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] bg-clip-text text-transparent">
          My Experience
        </h2>
      </div>

      <div className="container mx-auto max-w-6xl">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
          {skillCategories.map((category, index) => (
            <CategoryCard key={index} category={category} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;