import cartPNG from '../assets/cart.PNG'
import cartGIF from '../assets/cart.gif'
import tdPNG from '../assets/TD.PNG'
import tdGIF from '../assets/TD.gif'
import codeImgOne from '../assets/codeImg1.png'
import React, { useState, useEffect } from 'react';
import { Github, ExternalLink, Gamepad, Code, Globe, Tag, ArrowRight } from 'lucide-react';

const Portfolio = () => {
  const [activeCategory, setActiveCategory] = useState('games');
  const [hoveredProject, setHoveredProject] = useState(null);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Disable hover animations on mobile to improve performance
  const isMobileDevice = windowWidth < 768;

  const categories = [
    { id: 'games', label: 'Game Projects', icon: Gamepad },
    { id: 'automation', label: 'Automation', icon: Code },
    { id: 'frontend', label: 'Frontend', icon: Globe }
  ];

  const projects = {
    games: [
      {
        id: 1,
        image: cartPNG,
        gif: cartGIF,
        title: 'Crazy Cart',
        description: 'An exhilarating racing game featuring innovative cart mechanics and dynamically challenging tracks. Players navigate through diverse environments while mastering unique physics-based controls.',
        tags: ['Game Design', '3D', 'Unity', 'C#'],
        github: "https://github.com/AntonP210/GameDev/tree/main/CrazyCart(Original)",
        demo: "https://antonpas.itch.io/crazycart",
      },
      {
        id: 2,
        image: tdPNG,
        gif: tdGIF,
        title: 'Tower Defence',
        description: 'A cross-platform 3D strategy game that challenges players to master resource management and tactical tower placement. Features multiple enemy types and progressive difficulty scaling.',
        tags: ['Unity', 'C#', '3D'],
        github: "https://github.com/AntonP210/GameDev/tree/main/TowerDefense",
        demo: "https://antonpas.itch.io/towerdefense",
      },
    ],
    automation: [
      {
        id: 1,
        image: codeImgOne,
        title: 'Game Automation Testing',
        description: 'Advanced automation framework utilizing computer vision for element detection and verification within Android emulators. Implements sophisticated image recognition algorithms for reliable test execution.',
        tags: ['Python', 'Android', 'Pytest'],
        github: "https://github.com/AntonP210/game_automation",
      },
      {
        id: 2,
        image: codeImgOne,
        title: 'Python Selenium Testing',
        description: 'Comprehensive web automation testing infrastructure built with Selenium and Python. Features page object model architecture and extensive reporting capabilities.',
        tags: ['Python', 'Selenium', 'Pytest'],
        github: "https://github.com/AntonP210/python_selenium",
      },
      {
        id: 3,
        image: codeImgOne,
        title: 'TypeScript Selenium Testing',
        description: 'Modern web automation framework leveraging TypeScript and Selenium for robust testing. Includes strong typing, custom assertions, and integrated test reporting.',
        tags: ['TypeScript', 'Selenium', 'Jest'],
        github: "https://github.com/AntonP210/ts_selenium",
      },
    ],
    frontend: [
      // ... frontend projects
    ]
  };

  // Determine if we should show all tags or limit them on small screens
  const getVisibleTags = (tags, projectId) => {
    // On very small screens, show fewer tags
    if (windowWidth < 400) {
      return tags.slice(0, 2);
    }
    // On other mobile screens, show more but still limited
    if (windowWidth < 640) {
      return tags.slice(0, 3);
    }
    // On larger screens, show all tags
    return tags;
  };

  // Get icon size based on screen width
  const getIconSize = () => {
    if (windowWidth < 375) return "w-3 h-3"; // Extra small screens
    if (windowWidth < 640) return "w-4 h-4"; // Small screens
    return "w-5 h-5"; // Default
  };

  const ProjectCard = ({ project }) => {
    // Determine if we should show GIF on mobile (disabled to improve performance)
    const shouldShowGif = !isMobileDevice && hoveredProject === project.id && project.gif;
    const visibleTags = getVisibleTags(project.tags, project.id);
    
    return (
      <article
        className="group relative h-full"
        onMouseEnter={() => !isMobileDevice && setHoveredProject(project.id)}
        onMouseLeave={() => !isMobileDevice && setHoveredProject(null)}
      >
        {/* Background glow - reduced on mobile */}
        {!isMobileDevice && (
          <div className="absolute inset-0 bg-gradient-to-r from-[#5BC0BE]/20 to-[#6FFFE9]/20 rounded-xl sm:rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
        )}
        
        {/* Content container */}
        <div className="relative bg-[#0B132B]/50 backdrop-blur-lg rounded-xl sm:rounded-2xl border border-gray-800/50 group-hover:border-[#6FFFE9]/50 transition-all duration-300 overflow-hidden h-full flex flex-col">
          {/* Image */}
          <div className="relative aspect-video overflow-hidden">
            <img
              src={shouldShowGif ? project.gif : project.image}
              alt={project.title}
              className={`w-full h-full object-cover ${!isMobileDevice && 'transform group-hover:scale-105 transition-transform duration-500'}`}
              loading="lazy" // Add lazy loading for performance
            />
            
            {/* Tags overlay - show fewer tags on mobile */}
            <div className="absolute top-2 sm:top-4 left-2 sm:left-4 flex flex-wrap gap-1 sm:gap-2">
              {visibleTags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-[#0B132B]/90 backdrop-blur-sm text-[#6FFFE9] px-2 sm:px-3 py-0.5 sm:py-1 rounded-full text-xs sm:text-sm flex items-center gap-1"
                >
                  <Tag className="w-2.5 h-2.5 sm:w-3 sm:h-3" />
                  {tag}
                </span>
              ))}
              {project.tags.length > visibleTags.length && (
                <span className="bg-[#0B132B]/90 backdrop-blur-sm text-[#6FFFE9] px-2 sm:px-3 py-0.5 sm:py-1 rounded-full text-xs sm:text-sm">
                  +{project.tags.length - visibleTags.length}
                </span>
              )}
            </div>
          </div>

          {/* Project info */}
          <div className="p-4 sm:p-6 flex flex-col flex-grow">
            <h3 className="text-lg sm:text-xl font-bold mb-1 sm:mb-2 text-gray-100">{project.title}</h3>
            <p className="text-gray-400 text-xs sm:text-sm mb-3 sm:mb-4 flex-grow line-clamp-3 sm:line-clamp-none">
              {project.description}
            </p>

            {/* Action buttons */}
            <div className="flex gap-2 sm:gap-4 mt-auto">
              <a
                href={project.github}
                target="_blank"
                rel="noopener noreferrer"
                className={`flex items-center justify-center gap-1 sm:gap-2 bg-[#0B132B] hover:bg-[#1C2541] text-white py-2 sm:py-2.5 px-3 sm:px-4 rounded-lg sm:rounded-xl transition-all duration-300 group/btn border border-[#5BC0BE] text-xs sm:text-sm ${project.demo ? 'flex-1' : 'w-full'}`}
              >
                <Github className={`${getIconSize()} group-hover/btn:rotate-12 transition-transform duration-300`} />
                Github
              </a>
              {project.demo && (
                <a
                  href={project.demo}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-1 flex items-center justify-center gap-1 sm:gap-2 bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] py-2 sm:py-2.5 px-3 sm:px-4 rounded-lg sm:rounded-xl transition-all duration-300 group/btn hover:shadow-lg hover:shadow-[#5BC0BE]/20 text-xs sm:text-sm"
                >
                  <ExternalLink className={`${getIconSize()} group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1 transition-transform duration-300`} />
                  Live Demo
                </a>
              )}
            </div>
          </div>
        </div>
      </article>
    );
  };

  return (
    <section id="portfolio" className="py-16 sm:py-20 md:py-24 px-4 bg-gradient-to-b from-[#0B132B] to-[#1C2541]">
      <div className="text-center mb-8 sm:mb-12 md:mb-16">
        <h5 className="text-gray-400 text-base sm:text-lg mb-1 sm:mb-2">My Recent Work</h5>
        <h2 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] bg-clip-text text-transparent">
          Portfolio
        </h2>
      </div>

      {/* Category Tabs - Simplified for mobile */}
      <div className="container mx-auto max-w-6xl mb-8 sm:mb-12">
        <div className="flex justify-center gap-1 sm:gap-4 p-1 bg-[#0B132B]/30 backdrop-blur-sm rounded-xl sm:rounded-2xl overflow-x-auto">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              className={`flex items-center gap-1 sm:gap-2 px-3 sm:px-6 py-2 sm:py-3 rounded-lg sm:rounded-xl transition-all duration-300 whitespace-nowrap text-xs sm:text-base ${
                activeCategory === category.id 
                  ? 'bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] shadow-lg' 
                  : 'text-gray-400 hover:bg-[#0B132B]/50'
              }`}
            >
              <category.icon className={getIconSize()} />
              {category.label}
            </button>
          ))}
        </div>
      </div>

      {/* Projects Grid - Single column on mobile */}
      <div className="container mx-auto max-w-6xl">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
          {projects[activeCategory].map(project => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>

      {/* View More CTA */}
      <div className="text-center mt-10 sm:mt-16">
        <a
          href={activeCategory === 'games' ? "https://antonpas.itch.io/" : "https://github.com/AntonP210"}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-1 sm:gap-2 bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] px-5 sm:px-8 py-2.5 sm:py-3 rounded-lg sm:rounded-xl text-sm sm:text-base
            transition-all duration-300 hover:shadow-lg hover:shadow-[#5BC0BE]/20 group"
        >
          View More Projects
          <ArrowRight className={`${getIconSize()} group-hover:translate-x-1 transition-transform duration-300`} />
        </a>
      </div>
    </section>
  );
};

export default Portfolio;