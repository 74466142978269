import React, { useState, useEffect, useMemo } from 'react';
import { Home, User, BookOpen, Settings, MessageSquare, Menu, X } from 'lucide-react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('home');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Use useMemo to memoize the navItems array
  const navItems = useMemo(() => [
    { id: 'home', href: '#home', icon: Home, label: 'Home' },
    { id: 'about', href: '#about', icon: User, label: 'About' },
    { id: 'experience', href: '#experience', icon: BookOpen, label: 'Experience' },
    { id: 'services', href: '#services', icon: Settings, label: 'Services' },
    { id: 'contact', href: '#contact', icon: MessageSquare, label: 'Contact' }
  ], []); // Empty dependency array means this only runs once

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);

      const sections = navItems.map(item => ({
        id: item.id,
        element: document.querySelector(item.href)
      })).filter(item => item.element);

      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        if (!section.element) continue;

        const sectionTop = section.element.offsetTop;
        const sectionBottom = sectionTop + section.element.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
          setActiveNav(section.id);
          break;
        }
      }

      if (window.scrollY < 100) {
        setActiveNav('home');
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [navItems]);

  // Close mobile menu when window is resized to desktop size
  useEffect(() => {
    if (windowWidth >= 768 && isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [windowWidth, isMobileMenuOpen]);

  const handleNavClick = (e, href, id) => {
    e.preventDefault();
    setActiveNav(id);

    if (id === 'home') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      const element = document.querySelector(href);
      if (element) {
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
    setIsMobileMenuOpen(false);
  };

  // Get icon size based on screen width
  const getIconSize = (isMobile) => {
    if (windowWidth < 375) return isMobile ? "w-4 h-4" : "w-4 h-4"; // Extra small screens
    if (windowWidth < 640) return isMobile ? "w-5 h-5" : "w-4 h-4"; // Small screens
    return isMobile ? "w-5 h-5" : "w-5 h-5"; // Default
  };
  
  // Calculate position for mobile menu based on screen size
  const getMobileMenuPosition = () => {
    if (windowWidth < 400) {
      return "fixed bottom-20 right-0 left-0 mx-4 transition-all duration-300 transform";
    } 
    return "fixed bottom-24 right-6 transition-all duration-300 transform";
  };
  
  // Adjust the desktop nav position and size based on screen width
  const getDesktopNavStyles = () => {
    if (windowWidth >= 768 && windowWidth < 1024) {
      return "px-5 py-2.5 gap-2"; // Smaller on tablets
    }
    return "px-7 py-3 gap-3"; // Default size
  };

  const NavItem = ({ id, href, icon: Icon, label, isMobile = false }) => (
    <a
      href={href}
      onClick={(e) => handleNavClick(e, href, id)}
      className={`group relative ${
        isMobile 
          ? 'flex items-center gap-3 p-2.5 sm:p-3 rounded-xl w-full' 
          : 'p-3 sm:p-4 rounded-full hover:scale-110'
      } transition-all duration-300 ${
        activeNav === id 
          ? 'bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] shadow-lg shadow-[#5BC0BE]/20' 
          : 'text-gray-400 hover:bg-[#0B132B]/50'
      }`}
      aria-label={label}
      role="button"
    >
      <Icon className={`${getIconSize(isMobile)} ${isMobile ? '' : ''}`} />
      
      {/* Tooltip for desktop - hidden on very small screens */}
      {!isMobile && windowWidth >= 640 && (
        <span className="absolute -top-12 left-1/2 -translate-x-1/2 
          bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] px-2.5 py-1.5 rounded-lg text-xs sm:text-sm
          opacity-0 group-hover:opacity-100 transition-all duration-300
          whitespace-nowrap pointer-events-none
          before:content-[''] before:absolute before:left-1/2 before:-bottom-1
          before:-translate-x-1/2 before:border-4 before:border-transparent
          before:border-t-[#5BC0BE]">
          {label}
        </span>
      )}
      
      {/* Label for mobile */}
      {isMobile && <span className="text-xs sm:text-sm font-medium">{label}</span>}
    </a>
  );

  return (
    <>
      {/* Desktop Navigation */}
      <nav className={`fixed bottom-6 sm:bottom-8 left-1/2 hidden md:flex -translate-x-1/2 
        backdrop-blur-lg rounded-full z-50 transition-all duration-300 ${getDesktopNavStyles()}
        ${isScrolled 
          ? 'bg-[#0B132B]/90 shadow-lg shadow-[#5BC0BE]/5' 
          : 'bg-[#0B132B]/50'}`}>
        {navItems.map((item) => (
          <NavItem key={item.id} {...item} />
        ))}
      </nav>

      {/* Mobile Navigation */}
      <div className="md:hidden fixed bottom-4 sm:bottom-6 right-4 sm:right-6 z-50">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className={`p-3 sm:p-4 rounded-full transition-all duration-300
            ${isMobileMenuOpen 
              ? 'bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] rotate-180' 
              : 'bg-[#0B132B]/90 text-white'
            } shadow-lg shadow-[#5BC0BE]/20`}
          aria-label="Toggle navigation menu"
        >
          {isMobileMenuOpen ? <X className="w-5 h-5 sm:w-6 sm:h-6" /> : <Menu className="w-5 h-5 sm:w-6 sm:h-6" />}
        </button>

        <div className={`${getMobileMenuPosition()}
          ${isMobileMenuOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0 pointer-events-none'}`}>
          <div className="relative">
            {/* Reduced blur on small screens for performance */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#5BC0BE]/20 to-[#6FFFE9]/20 rounded-xl sm:rounded-2xl blur-lg sm:blur-xl" />
            <div className="relative bg-[#0B132B]/80 backdrop-blur-lg rounded-xl sm:rounded-2xl p-3 sm:p-4 shadow-xl
              flex flex-col gap-1.5 sm:gap-2 items-end sm:items-end border border-gray-800/50">
              {navItems.map((item) => (
                <NavItem key={item.id} {...item} isMobile />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;