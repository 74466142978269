import React, { useState, useEffect } from 'react';
import { Github, Linkedin, MoveDown } from 'lucide-react';
import ME from '../assets/face.jpg'

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const ProfileImage = ({ image }) => (
    <div className="relative w-80 h-80 mx-auto mt-12">
      {/* Controlled aura effect */}
      <div className="absolute inset-0 -m-6">
        <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#5BC0BE]/30 to-[#6FFFE9]/30 blur-xl" />
        <div className="absolute inset-0 -m-4 rounded-full bg-gradient-to-r from-[#5BC0BE]/20 to-[#6FFFE9]/20 blur-2xl" />
      </div>
      
      {/* Main image container */}
      <div className="relative rounded-full overflow-hidden border-4 border-[#6FFFE9]/50 backdrop-blur-sm">
        <img
          src={image}
          alt="Profile"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Experience badge */}
      <div className="absolute bottom-4 right-0 transform translate-x-1/4">
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] rounded-full blur-sm opacity-75" />
          <div className="relative bg-[#0B132B]/80 backdrop-blur-sm px-4 py-2 rounded-full border border-[#6FFFE9]/50">
            <span className="text-sm font-medium text-[#6FFFE9]">5+ Years Experience</span>
          </div>
        </div>
      </div>
    </div>
  );

  const SocialLinks = () => (
    <div className="flex gap-6 justify-center mt-8">
      <a
        href="https://github.com/AntonP210"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-400 hover:text-[#5BC0BE] transition-colors duration-300"
        aria-label="Github Profile"
      >
        <Github size={24} />
      </a>
      <a
        href="https://www.linkedin.com/in/anton-pasternak/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-400 hover:text-[#5BC0BE] transition-colors duration-300"
        aria-label="LinkedIn Profile"
      >
        <Linkedin size={24} />
      </a>
    </div>
  );

  const CTA = () => (
    <div className="flex justify-center gap-4 mt-8">
      <a
        href="https://drive.google.com/uc?export=download&id=1-2ciOT5TzoVsp9Fuz_eMkXul3xXwEk2N"
        download
        className="px-8 py-3 bg-[#5BC0BE] text-white rounded-lg hover:bg-[#3A506B] transition-colors duration-300"
      >
        Download CV
      </a>
      <a
        href="#contact"
        className="px-8 py-3 border border-[#5BC0BE] text-[#5BC0BE] rounded-lg hover:bg-[#5BC0BE]/10 transition-colors duration-300"
      >
        Let's Talk
      </a>
    </div>
  );

  return (
    <header className="min-h-screen pt-32 overflow-hidden bg-[#0B132B] relative">
      {/* Background effects */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#0B132B] to-[#1C2541]" />
      <div className="absolute inset-0 opacity-30">
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-[#5BC0BE]/20 rounded-full filter blur-3xl animate-pulse" />
        <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-[#6FFFE9]/20 rounded-full filter blur-3xl animate-pulse delay-1000" />
      </div>

      {/* Main content */}
      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center">
          <div className={`space-y-6 transition-all duration-1000 transform ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}>
            {/* Introduction */}
            <p className="text-gray-400 text-lg">Hello I'm</p>
            
            {/* Name */}
            <h1 className="text-6xl md:text-7xl font-bold">
              <span className="bg-gradient-to-r from-[#5BC0BE] via-[#3A506B] to-[#6FFFE9] bg-clip-text text-transparent">
                Anton Pasternak
              </span>
            </h1>
            
            {/* Role */}
            <h2 className="text-2xl text-gray-300">
              Automation Engineer & Game Developer
            </h2>
            
            {/* Description */}
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              Crafting automated solutions and immersive gaming experiences with 
              a passion for innovation and technical excellence
            </p>

            {/* Buttons */}
            <CTA />

            {/* Social Links */}
            <SocialLinks />
          </div>

          {/* Profile Image */}
          <div className="mt-16">
            <ProfileImage image={ME} />
          </div>

          {/* Scroll Indicator */}
          <div className="mt-16 text-gray-400 flex justify-center items-center gap-2 cursor-pointer hover:text-[#6FFFE9] transition-colors duration-300">
            <a href="#about" className="flex items-center gap-2">
              <span>Scroll Down</span>
              <MoveDown size={20} className="animate-bounce" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;