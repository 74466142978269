import React, { useRef, useState, useEffect } from 'react';
import { Mail, Send, MessageSquare, Loader, CheckCircle, XCircle, AtSign, Linkedin, ArrowRight } from 'lucide-react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [focusedField, setFocusedField] = useState(null);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const contactOptions = [
    {
      icon: Mail,
      title: "Email",
      value: "antonpas.dev@gmail.com",
      link: "mailto:antonpas.dev@gmail.com",
      color: "from-[#5BC0BE] to-[#6FFFE9]"
    },
    {
      icon: Linkedin,
      title: "LinkedIn",
      value: "Let's connect professionally",
      link: "https://www.linkedin.com/in/anton-pasternak/",
      color: "from-[#6FFFE9] to-[#5BC0BE]"
    }
  ];

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      await emailjs.sendForm(
        'gmail',
        'template_81xqq9m',
        form.current,
        '3T9O-Gkc5bAIBm1m9'
      );
      setSubmitStatus('success');
      e.target.reset();
    } catch (error) {
      console.error('Failed to send message:', error.text);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setSubmitStatus(null), 5000);
    }
  };

  // Get icon size based on screen width
  const getIconSize = () => {
    if (windowWidth < 375) return "w-4 h-4"; // Extra small screens
    if (windowWidth < 640) return "w-5 h-5"; // Small screens
    return "w-5 h-5"; // Default
  };

  // Determine if we should use compact layout
  const isCompact = windowWidth < 400;

  const ContactOption = ({ option }) => (
    <div className="relative group">
      {/* Background glow - reduced on small screens */}
      <div className={`absolute inset-0 bg-gradient-to-r ${option.color} rounded-xl sm:rounded-2xl blur-lg sm:blur-xl opacity-20 group-hover:opacity-30 transition-all duration-500`} />
      
      {/* Content container - reduced padding on mobile */}
      <div className="relative bg-[#0B132B]/50 backdrop-blur-lg rounded-xl sm:rounded-2xl p-4 sm:p-6 border border-gray-800/50 group-hover:border-[#6FFFE9]/50 transition-all duration-300">
        <div className={`w-10 h-10 sm:w-12 sm:h-12 mx-auto mb-3 sm:mb-4 rounded-lg sm:rounded-xl bg-gradient-to-r ${option.color} p-2 sm:p-2.5 transform group-hover:scale-110 transition-transform duration-300`}>
          <option.icon className="w-full h-full text-[#0B132B]" />
        </div>
        
        <h4 className="text-lg sm:text-xl font-medium text-gray-200 mb-1 sm:mb-2">{option.title}</h4>
        <p className="text-sm sm:text-base text-gray-400 mb-3 sm:mb-4">{option.value}</p>
        
        <a
          href={option.link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-1 sm:gap-2 text-sm sm:text-base text-[#6FFFE9] hover:text-[#5BC0BE] transition-colors duration-300 group/link"
        >
          Send a message
          <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 group-hover/link:translate-x-1 transition-transform duration-300" />
        </a>
      </div>
    </div>
  );

  return (
    <section id="contact" className="py-16 sm:py-20 md:py-24 px-4 bg-gradient-to-b from-[#0B132B] to-[#1C2541]">
      <div className="text-center mb-10 sm:mb-16">
        <h5 className="text-gray-400 text-base sm:text-lg mb-1 sm:mb-2">Get In Touch</h5>
        <h2 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] bg-clip-text text-transparent">
          Contact Me
        </h2>
        <p className="text-sm sm:text-base text-gray-400 mt-2 sm:mt-4 max-w-2xl mx-auto px-2">
          Let's discuss your project or explore opportunities for collaboration
        </p>
      </div>

      <div className="container mx-auto max-w-6xl grid md:grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12">
        {/* Contact Options - Row on small screens, column on larger screens */}
        <div className={`${isCompact ? 'space-y-4' : 'grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 lg:grid-cols-1 gap-4'}`}>
          {contactOptions.map((option, index) => (
            <ContactOption key={index} option={option} />
          ))}
        </div>

        {/* Contact Form */}
        <div className="relative">
          {/* Form background glow - reduced on mobile */}
          <div className="absolute inset-0 bg-gradient-to-r from-[#5BC0BE]/20 to-[#6FFFE9]/20 rounded-xl sm:rounded-2xl blur-lg sm:blur-xl opacity-30 sm:opacity-50" />
          
          <form
            ref={form}
            onSubmit={sendEmail}
            className="relative bg-[#0B132B]/50 backdrop-blur-lg p-5 sm:p-6 md:p-8 rounded-xl sm:rounded-2xl border border-gray-800/50 space-y-4 sm:space-y-6"
          >
            {/* Name input - Smaller padding on mobile */}
            <div className="relative group">
              <input
                type="text"
                name="name"
                placeholder="Your Full Name"
                required
                onFocus={() => setFocusedField('name')}
                onBlur={() => setFocusedField(null)}
                className={`w-full bg-[#0B132B]/50 rounded-lg sm:rounded-xl p-3 sm:p-4 pl-10 sm:pl-12 border text-sm sm:text-base transition-all duration-300
                  ${focusedField === 'name' 
                    ? 'border-[#5BC0BE] shadow-lg shadow-[#5BC0BE]/20' 
                    : 'border-gray-700 hover:border-gray-600'}`}
              />
              <AtSign className={`absolute left-3 sm:left-4 top-1/2 -translate-y-1/2 ${getIconSize()} transition-colors duration-300
                ${focusedField === 'name' ? 'text-[#6FFFE9]' : 'text-gray-500'}`} />
            </div>

            {/* Email input */}
            <div className="relative group">
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                required
                onFocus={() => setFocusedField('email')}
                onBlur={() => setFocusedField(null)}
                className={`w-full bg-[#0B132B]/50 rounded-lg sm:rounded-xl p-3 sm:p-4 pl-10 sm:pl-12 border text-sm sm:text-base transition-all duration-300
                  ${focusedField === 'email' 
                    ? 'border-[#5BC0BE] shadow-lg shadow-[#5BC0BE]/20' 
                    : 'border-gray-700 hover:border-gray-600'}`}
              />
              <Mail className={`absolute left-3 sm:left-4 top-1/2 -translate-y-1/2 ${getIconSize()} transition-colors duration-300
                ${focusedField === 'email' ? 'text-[#6FFFE9]' : 'text-gray-500'}`} />
            </div>

            {/* Message textarea - Fewer default rows on mobile */}
            <div className="relative group">
              <textarea
                name="message"
                rows={windowWidth < 480 ? "5" : "7"}
                placeholder="Your Message"
                required
                onFocus={() => setFocusedField('message')}
                onBlur={() => setFocusedField(null)}
                className={`w-full bg-[#0B132B]/50 rounded-lg sm:rounded-xl p-3 sm:p-4 pl-10 sm:pl-12 border text-sm sm:text-base transition-all duration-300 resize-y
                  ${focusedField === 'message' 
                    ? 'border-[#5BC0BE] shadow-lg shadow-[#5BC0BE]/20' 
                    : 'border-gray-700 hover:border-gray-600'}`}
              />
              <MessageSquare className={`absolute left-3 sm:left-4 top-5 sm:top-6 ${getIconSize()} transition-colors duration-300
                ${focusedField === 'message' ? 'text-[#6FFFE9]' : 'text-gray-500'}`} />
            </div>

            {/* Submit button - Smaller on mobile */}
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] font-medium py-3 sm:py-4 px-4 sm:px-6 rounded-lg sm:rounded-xl text-sm sm:text-base
                transition-all duration-300 flex items-center justify-center gap-2 group
                hover:shadow-lg hover:shadow-[#5BC0BE]/20 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <Loader className={getIconSize() + " animate-spin"} />
              ) : submitStatus === 'success' ? (
                <CheckCircle className={getIconSize()} />
              ) : submitStatus === 'error' ? (
                <XCircle className={getIconSize()} />
              ) : (
                <>
                  Send Message
                  <Send className={`${getIconSize()} group-hover:translate-x-1 transition-transform duration-300`} />
                </>
              )}
            </button>

            {/* Status message */}
            {submitStatus && (
              <div className={`text-center text-xs sm:text-sm ${
                submitStatus === 'success' ? 'text-[#6FFFE9]' : 'text-[#5BC0BE]'
              }`}>
                {submitStatus === 'success' 
                  ? 'Message sent successfully!' 
                  : 'Failed to send message. Please try again.'}
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;