import React, { useState, useEffect } from 'react';
import { Award, Code, FolderGit2, MapPin, Briefcase, Gamepad, Terminal } from 'lucide-react';
import ME2 from '../assets/face2.PNG';

const About = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const stats = [
    {
      icon: Award,
      title: "Experience",
      value: "5+ Years",
      color: "from-[#5BC0BE]/20 to-[#3A506B]/20"
    },
    {
      icon: Code,
      title: "Technologies",
      value: "15+ Stack",
      color: "from-[#6FFFE9]/20 to-[#5BC0BE]/20"
    },
    {
      icon: FolderGit2,
      title: "Projects",
      value: "6+ Completed",
      color: "from-[#6FFFE9]/20 to-[#3A506B]/20"
    }
  ];

  const tabs = [
    { id: 'overview', label: 'Overview', icon: Briefcase },
    { id: 'gaming', label: 'Game Dev', icon: Gamepad },
    { id: 'automation', label: 'Automation', icon: Terminal }
  ];

  const content = {
    overview: "Motivated Software Developer with extensive experience managing large-scale projects, including automation pipelines and game development. Passionate about creating efficient solutions and delivering exceptional results.",
    gaming: "Dedicated game developer with a passion for creating immersive experiences. Experienced in various gaming technologies and platforms, focusing on performance optimization and user experience.",
    automation: "Specialized in developing automated solutions and optimizing workflows. Expert in creating efficient pipelines and implementing automated processes that save time and reduce errors."
  };

  // Render icons at appropriate size based on screen width
  const getIconSize = () => {
    if (windowWidth <= 375) return "w-5 h-5"; // Small phones
    if (windowWidth <= 768) return "w-6 h-6"; // Regular phones
    return "w-6 h-6"; // Tablets and larger
  };

  const StatCard = ({ icon: Icon, title, value, color }) => (
    <div className="relative group">
      <div className={`absolute inset-0 rounded-2xl bg-gradient-to-r ${color} blur-xl opacity-50 group-hover:opacity-100 transition-opacity duration-500`} />
      <div className="relative h-full bg-[#0B132B]/50 backdrop-blur-lg p-4 sm:p-5 md:p-6 rounded-2xl border border-gray-800/50 group-hover:border-[#6FFFE9]/50 transition-all duration-300">
        <div className="flex flex-col items-center gap-2 sm:gap-3">
          <Icon className={`${getIconSize()} text-[#6FFFE9] group-hover:scale-110 transition-transform duration-300`} />
          <h5 className="text-base sm:text-lg font-medium text-white">{title}</h5>
          <p className="text-sm sm:text-base text-gray-400">{value}</p>
        </div>
      </div>
    </div>
  );

  // Determine if we should use a more compact layout for tabs
  const isCompactView = windowWidth < 480;

  return (
    <section id="about" className="py-16 sm:py-20 md:py-24 px-4 bg-gradient-to-b from-[#0B132B] to-[#1C2541]">
      <div className="text-center mb-10 sm:mb-12 md:mb-16">
        <h5 className="text-gray-400 text-base sm:text-lg mb-1 sm:mb-2">Get To Know</h5>
        <h2 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] bg-clip-text text-transparent">
          About Me
        </h2>
      </div>

      <div className="container mx-auto max-w-6xl grid md:grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12 md:gap-16 items-center">
        {/* Image Section - Centered on mobile */}
        <div className="relative max-w-xs sm:max-w-sm md:max-w-md mx-auto lg:mx-0 group">
          {/* Background glow */}
          <div className="absolute -inset-3 sm:-inset-4 bg-gradient-to-r from-[#5BC0BE]/30 to-[#6FFFE9]/30 rounded-3xl blur-xl sm:blur-2xl group-hover:blur-3xl transition-all duration-500" />
          
          {/* Image container - Smaller on mobile */}
          <div className="relative aspect-square rounded-2xl sm:rounded-3xl overflow-hidden">
            <img
              src={ME2}
              alt="About Anton"
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
              loading="lazy" // Add lazy loading for performance
            />
            {/* Gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-[#0B132B]/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          </div>

          {/* Location badge - Smaller on mobile */}
          <div className="absolute bottom-3 left-3 sm:bottom-4 sm:left-4 flex items-center gap-1 sm:gap-2 bg-[#0B132B]/90 backdrop-blur-sm px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm text-gray-300">
            <MapPin className="w-3 h-3 sm:w-4 sm:h-4 text-[#6FFFE9]" />
            Petah Tiqwa, Israel
          </div>
        </div>

        {/* Content Section */}
        <div className="flex flex-col gap-6 sm:gap-8">
          {/* Stats Grid - Single column on smallest screens */}
          <div className="grid grid-cols-1 xs:grid-cols-3 gap-3 sm:gap-4">
            {stats.map((stat, index) => (
              <StatCard key={index} {...stat} />
            ))}
          </div>

          {/* Tabs Section */}
          <div className="space-y-4 sm:space-y-6">
            {/* Tab Buttons - Icon only on smallest screens */}
            <div className="flex flex-wrap justify-center sm:justify-start gap-2 sm:gap-4 p-1 bg-[#0B132B]/30 backdrop-blur-sm rounded-lg sm:rounded-xl">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center gap-1 sm:gap-2 px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg transition-all duration-300 ${
                    activeTab === tab.id 
                      ? 'bg-[#5BC0BE] text-white shadow-lg' 
                      : 'text-gray-400 hover:bg-[#0B132B]/50'
                  }`}
                  aria-label={tab.label}
                >
                  <tab.icon className="w-4 h-4" />
                  {/* Hide text on very small screens if needed */}
                  <span className={isCompactView ? 'sr-only' : ''}>
                    {tab.label}
                  </span>
                </button>
              ))}
            </div>

            {/* Tab Content - Adjust padding on mobile */}
            <div className="bg-[#0B132B]/30 backdrop-blur-sm p-4 sm:p-6 rounded-xl sm:rounded-2xl border border-gray-800/50">
              <p className="text-sm sm:text-base leading-relaxed text-gray-300">
                {content[activeTab]}
              </p>
            </div>
          </div>

          {/* CTA Button - Center on mobile */}
          <div className="flex justify-center sm:justify-start">
            <a
              href="#contact"
              className="group inline-flex items-center gap-2 bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-white px-6 sm:px-8 py-2.5 sm:py-3 rounded-lg 
                transition-all duration-300 hover:shadow-lg hover:shadow-[#5BC0BE]/20 hover:scale-105 text-sm sm:text-base"
            >
              Let's Talk
              <span className="transform group-hover:translate-x-1 transition-transform duration-300">→</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;