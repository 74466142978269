import React, { useEffect, useState } from 'react';
import { Github, Linkedin, ArrowUp, Mail, Heart } from 'lucide-react';

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigationLinks = [
    { href: '#', label: 'Home' },
    { href: '#about', label: 'About' },
    { href: '#experience', label: 'Experience' },
    { href: '#services', label: 'Services' },
    { href: '#portfolio', label: 'Portfolio' },
    { href: '#contact', label: 'Contact' }
  ];

  const socialLinks = [
    {
      href: "https://github.com/AntonP210",
      icon: Github,
      label: "GitHub Profile",
      color: "from-[#5BC0BE] to-[#3A506B]"
    },
    {
      href: "https://www.linkedin.com/in/anton-pasternak/",
      icon: Linkedin,
      label: "LinkedIn Profile",
      color: "from-[#5BC0BE] to-[#6FFFE9]"
    },
    {
      href: "mailto:antonpas.dev@gmail.com",
      icon: Mail,
      label: "Email Contact",
      color: "from-[#6FFFE9] to-[#5BC0BE]"
    }
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Determine if we should hide background effects on small screens for performance
  const shouldReduceEffects = windowWidth < 640;
  
  // Calculate appropriate icon sizes based on screen width
  const getIconSize = () => {
    if (windowWidth < 375) return "w-4 h-4"; // Extra small screens
    if (windowWidth < 640) return "w-5 h-5"; // Small screens
    return "w-5 h-5"; // Default
  };

  // Adjust nav links display based on screen width
  const getNavDisplay = () => {
    if (windowWidth < 480) {
      // Display in 3x2 grid on very small screens
      return "grid grid-cols-2 sm:flex sm:flex-wrap justify-center gap-x-4 sm:gap-x-8 gap-y-4";
    }
    // Regular display for larger screens
    return "flex flex-wrap justify-center gap-x-5 sm:gap-x-8 gap-y-4";
  };

  return (
    <footer className="relative bg-gradient-to-b from-[#0B132B] to-[#1C2541] py-10 sm:py-12 md:py-16 px-4">
      {/* Background Effects - hidden or reduced on small screens */}
      {!shouldReduceEffects && (
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute top-1/4 left-1/4 w-64 sm:w-96 h-64 sm:h-96 bg-[#5BC0BE]/20 rounded-full filter blur-2xl sm:blur-3xl opacity-10 sm:opacity-20" />
          <div className="absolute bottom-1/4 right-1/4 w-64 sm:w-96 h-64 sm:h-96 bg-[#6FFFE9]/20 rounded-full filter blur-2xl sm:blur-3xl opacity-10 sm:opacity-20" />
        </div>
      )}

      <div className="container mx-auto max-w-6xl relative">
        {/* Scroll to Top Button - adjusted position for mobile */}
        <button
          onClick={scrollToTop}
          className="absolute right-0 sm:right-4 -top-6 sm:-top-8 bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] p-2 sm:p-3 rounded-lg sm:rounded-xl
            transition-all duration-300 hover:shadow-lg hover:shadow-[#5BC0BE]/20 group"
          aria-label="Scroll to top"
        >
          <ArrowUp className={`${getIconSize()} group-hover:-translate-y-1 transition-transform duration-300`} />
        </button>

        {/* Logo - smaller on mobile */}
        <div className="text-center sm:text-left">
          <a
            href="#"
            className="inline-block text-2xl sm:text-3xl font-bold bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] bg-clip-text text-transparent
              hover:scale-105 transition-all duration-300"
          >
            Anton Pasternak
          </a>
        </div>

        {/* Navigation Links - adjusts layout on smaller screens */}
        <nav className="my-8 sm:my-10 md:my-12">
          <ul className={getNavDisplay()}>
            {navigationLinks.map(({ href, label }) => (
              <li key={href}>
                <a
                  href={href}
                  className="text-sm sm:text-base text-gray-400 hover:text-[#6FFFE9] transition-colors duration-300 relative group py-1 px-2 sm:px-0"
                >
                  <span className="relative z-10">{label}</span>
                  <span className="absolute inset-x-0 -bottom-1 h-0.5 bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] 
                    transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left" />
                </a>
              </li>
            ))}
          </ul>
        </nav>

        {/* Social Links - smaller on mobile */}
        <div className="flex justify-center gap-3 sm:gap-6 mb-8 sm:mb-12">
          {socialLinks.map(({ href, icon: Icon, label, color }) => (
            <a
              key={href}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={label}
              className="relative group"
            >
              {/* Reduce blur effects on small screens */}
              {!shouldReduceEffects && (
                <div className="absolute inset-0 bg-gradient-to-r from-[#5BC0BE]/20 to-[#6FFFE9]/20 rounded-lg sm:rounded-xl blur-md sm:blur-lg opacity-0 
                  group-hover:opacity-100 transition-opacity duration-300" />
              )}
              <div className={`relative bg-[#0B132B]/50 p-2 sm:p-3 rounded-lg sm:rounded-xl transition-all duration-300 
                hover:bg-gradient-to-r ${color} group-hover:scale-110 group-hover:shadow-lg group-hover:shadow-[#5BC0BE]/20`}>
                <Icon className={`${getIconSize()} text-gray-400 group-hover:text-[#0B132B] transition-colors duration-300`} />
              </div>
            </a>
          ))}
        </div>

        {/* Copyright - smaller text on mobile */}
        <div className="border-t border-gray-800/50 pt-6 sm:pt-8">
          <p className="text-xs sm:text-sm text-gray-400 flex items-center justify-center gap-1 sm:gap-2">
            <span>© {new Date().getFullYear()} Anton Pasternak. Made with</span>
            <Heart className="w-3 h-3 sm:w-4 sm:h-4 text-[#5BC0BE] animate-pulse" />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;