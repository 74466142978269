import React, { useState, useEffect } from 'react';
import { Code, Globe, Gamepad, ArrowRight, Clock, Zap, Server } from 'lucide-react';

const Services = () => {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const servicesList = [
    {
      title: "Software Automation",
      icon: Code,
      description: "Streamline your workflow with custom automation solutions",
      cta: "Automate Your Workflow",
      items: [
        {
          text: "Develop efficient automation scripts",
          icon: Clock,
        },
        {
          text: "Create custom process automation",
          icon: Zap,
        },
        {
          text: "Implement workflow optimization",
          icon: Server,
        }
      ]
    },
    {
      title: "Web Development",
      icon: Globe,
      description: "Build modern, responsive web applications",
      cta: "Start Your Project",
      items: [
        {
          text: "Design user-centric interfaces",
          icon: Globe,
        },
        {
          text: "Develop scalable applications",
          icon: Code,
        },
        {
          text: "Deploy and maintain solutions",
          icon: Server,
        }
      ]
    },
    {
      title: "Game Development",
      icon: Gamepad,
      description: "Create immersive gaming experiences",
      cta: "Create Your Game",
      items: [
        {
          text: "Design engaging game mechanics",
          icon: Gamepad,
        },
        {
          text: "Implement smooth gameplay",
          icon: Code,
        },
        {
          text: "Optimize for multiple platforms",
          icon: Globe,
        }
      ]
    }
  ];

  const handleContactClick = (e) => {
    e.preventDefault();
    const contactSection = document.querySelector('#contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Determine if we should reduce visual effects for performance
  const shouldReduceEffects = windowWidth < 640;
  
  // Get appropriate icon sizes based on screen width
  const getIconSize = (isFeatureIcon = false) => {
    if (isFeatureIcon) {
      // Feature icons (inside the lists)
      if (windowWidth < 375) return "w-8 h-8"; // Extra small screens
      if (windowWidth < 640) return "w-9 h-9"; // Small screens
      return "w-10 h-10"; // Default
    } else {
      // Main service icons
      if (windowWidth < 375) return "w-12 h-12"; // Extra small screens
      if (windowWidth < 640) return "w-14 h-14"; // Small screens
      return "w-16 h-16"; // Default
    }
  };
  
  // Determine padding based on screen size
  const getCardPadding = () => {
    if (windowWidth < 375) return "p-4"; // Extra small screens
    if (windowWidth < 640) return "p-5"; // Small screens
    return "p-8"; // Default
  };

  const ServiceCard = ({ service }) => (
    <div className="relative h-full">
      <div className="relative h-full flex flex-col bg-[#0B132B]/20 backdrop-blur-xl rounded-xl sm:rounded-2xl border border-gray-800/30">
        {/* Header - reduced padding on mobile */}
        <div className={`${getCardPadding()} text-center`}>
          {/* Icon with gradient background - smaller on mobile */}
          <div className={`${getIconSize()} mx-auto mb-4 sm:mb-6`}>
            <div className="w-full h-full rounded-xl sm:rounded-2xl bg-gradient-to-br from-[#5BC0BE] to-[#6FFFE9] p-3 sm:p-4">
              <service.icon className="w-full h-full text-[#0B132B]" />
            </div>
          </div>
          
          <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-white">{service.title}</h3>
          <p className="text-gray-400 text-xs sm:text-sm">{service.description}</p>
        </div>

        {/* Service Features - smaller on mobile */}
        <div className={`px-4 sm:px-8 space-y-4 sm:space-y-6 flex-grow`}>
          {service.items.map((item, itemIndex) => (
            <div 
              key={itemIndex}
              className="flex items-center gap-3 sm:gap-4"
            >
              <div className={`flex-shrink-0 ${getIconSize(true)} rounded-xl sm:rounded-2xl bg-gradient-to-br from-[#5BC0BE] to-[#6FFFE9] p-2 sm:p-2.5`}>
                <item.icon className="w-full h-full text-[#0B132B]" />
              </div>
              <p className="text-gray-300 text-xs sm:text-sm">{item.text}</p>
            </div>
          ))}
        </div>

        {/* CTA Button - smaller padding on mobile */}
        <div className={`${getCardPadding()} mt-auto`}>
          <button 
            onClick={handleContactClick}
            className="w-full bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] text-[#0B132B] py-3 sm:py-4 px-4 sm:px-6 rounded-lg sm:rounded-xl 
              flex items-center justify-center gap-2 group hover:shadow-lg hover:shadow-[#5BC0BE]/20 
              transition-all duration-300 hover:scale-[1.02] active:scale-[0.98] text-sm sm:text-base"
          >
            <span className="font-medium">{service.cta}</span>
            <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 group-hover:translate-x-1 transition-transform duration-300" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <section id="services" className="py-16 sm:py-20 md:py-24 px-4 bg-[#0B132B] relative overflow-hidden">
      {/* Background glow effects - reduced or hidden on mobile for performance */}
      {!shouldReduceEffects ? (
        <div className="absolute inset-0 opacity-30">
          <div className="absolute top-1/4 -left-1/4 w-64 sm:w-96 h-64 sm:h-96 bg-[#5BC0BE]/20 rounded-full filter blur-2xl sm:blur-3xl" />
          <div className="absolute bottom-1/4 -right-1/4 w-64 sm:w-96 h-64 sm:h-96 bg-[#6FFFE9]/20 rounded-full filter blur-2xl sm:blur-3xl" />
        </div>
      ) : (
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-1/4 -left-1/4 w-64 h-64 bg-[#5BC0BE]/10 rounded-full filter blur-xl" />
          <div className="absolute bottom-1/4 -right-1/4 w-64 h-64 bg-[#6FFFE9]/10 rounded-full filter blur-xl" />
        </div>
      )}

      <div className="container mx-auto max-w-6xl relative">
        <div className="text-center mb-10 sm:mb-16">
          <h5 className="text-gray-400 text-base sm:text-lg mb-1 sm:mb-2">What I Offer</h5>
          <h2 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-[#5BC0BE] to-[#6FFFE9] bg-clip-text text-transparent">
            My Services
          </h2>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {servicesList.map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;